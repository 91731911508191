export interface MessageModel {
  user: number
  type: 'in' | 'out'
  text: string
  time: string
  template?: boolean
}

const defaultMessages: Array<MessageModel> = [
  {
    user: 4,
    type: 'in',
    text: 'How likely are you to recommend our company to your friends and family ?',
    time: '2 mins',
  },
  {
    user: 2,
    type: 'out',
    text: 'Hey there, we’re just writing to let you know that you’ve been subscribed to a repository on GitHub.',
    time: '5 mins',
  },
  {
    user: 4,
    type: 'in',
    text: 'Ok, Understood!',
    time: '1 Hour',
  },
  {
    user: 2,
    type: 'out',
    text: 'You’ll receive notifications for all issues, pull requests!',
    time: '2 Hours',
  },
  {
    user: 4,
    type: 'in',
    text: 'You can unwatch this repository immediately by clicking here: <a href="https://keenthemes.com">Keenthemes.com</a>',
    time: '3 Hours',
  },
  {
    user: 2,
    type: 'out',
    text: 'Most purchased Business courses during this sale!',
    time: '4 Hours',
  },
  {
    user: 4,
    type: 'in',
    text: 'Company BBQ to celebrate the last quater achievements and goals. Food and drinks provided',
    time: '5 Hours',
  },
  {
    template: true,
    user: 2,
    type: 'out',
    text: '',
    time: 'Just now',
  },
  {
    template: true,
    user: 4,
    type: 'in',
    text: 'Right before vacation season we have the next Big Deal for you.',
    time: 'Just now',
  },
]



export interface UserInfoModel {
  initials?: { label: string; state: 'warning' | 'danger' | 'primary' | 'success' | 'info' }
  name: string
  avatar?: string
  email: string
  position: string
  online: boolean
}

const defaultUserInfos: Array<UserInfoModel> = [
  {
    name: 'Emma Smith',
    avatar: 'avatars/300-6.jpg',
    email: 'e.smith@kpmg.com.au',
    position: 'Art Director',
    online: false,
  },
  {
    name: 'Melody Macy',
    initials: { label: 'M', state: 'danger' },
    email: 'melody@altbox.com',
    position: 'Marketing Analytic',
    online: true,
  },
  {
    name: 'Max Smith',
    avatar: 'avatars/300-1.jpg',
    email: 'max@kt.com',
    position: 'Software Enginer',
    online: false,
  },
  {
    name: 'Sean Bean',
    avatar: 'avatars/300-5.jpg',
    email: 'sean@dellito.com',
    position: 'Web Developer',
    online: false,
  },
  {
    name: 'Brian Cox',
    avatar: 'avatars/300-25.jpg',
    email: 'brian@exchange.com',
    position: 'UI/UX Designer',
    online: false,
  },
  {
    name: 'Mikaela Collins',
    initials: { label: 'M', state: 'warning' },
    email: 'mikaela@pexcom.com',
    position: 'Head Of Marketing',
    online: true,
  },
  {
    name: 'Francis Mitcham',
    avatar: 'avatars/300-9.jpg',
    email: 'f.mitcham@kpmg.com.au',
    position: 'Software Arcitect',
    online: false,
  },

  {
    name: 'Olivia Wild',
    initials: { label: 'O', state: 'danger' },
    email: 'olivia@corpmail.com',
    position: 'System Admin',
    online: true,
  },
  {
    name: 'Neil Owen',
    initials: { label: 'N', state: 'primary' },
    email: 'owen.neil@gmail.com',
    position: 'Account Manager',
    online: true,
  },
  {
    name: 'Dan Wilson',
    avatar: 'avatars/300-23.jpg',
    email: 'dam@consilting.com',
    position: 'Web Desinger',
    online: false,
  },
  {
    name: 'Emma Bold',
    initials: { label: 'E', state: 'danger' },
    email: 'emma@intenso.com',
    position: 'Corporate Finance',
    online: true,
  },
  {
    name: 'Ana Crown',
    avatar: 'avatars/300-12.jpg',
    email: 'ana.cf@limtel.com',
    position: 'Customer Relationship',
    online: false,
  },
  {
    name: 'Robert Doe',
    initials: { label: 'A', state: 'info' },
    email: 'robert@benko.com',
    position: 'Marketing Executive',
    online: true,
  },
  {
    name: 'John Miller',
    avatar: 'avatars/300-13.jpg',
    email: 'miller@mapple.com',
    position: 'Project Manager',
    online: false,
  },
  {
    name: 'Lucy Kunic',
    initials: { label: 'L', state: 'success' },
    email: 'lucy.m@fentech.com',
    position: 'SEO Master',
    online: true,
  },
  {
    name: 'Ethan Wilder',
    avatar: 'avatars/300-21.jpg',
    email: 'ethan@loop.com.au',
    position: 'Accountant',
    online: true,
  },
]



const messageFromClient: MessageModel = {
  user: 4,
  type: 'in',
  text: 'Thank you for your awesome support!',
  time: 'Just now',
}

export interface AlertModel {
  title: string
  description: string
  time: string
  icon: string
  state: 'primary' | 'danger' | 'warning' | 'success' | 'info'
}


const defaultAlerts: Array<AlertModel> = [
  {
    title: 'Project Alice',
    description: 'Phase 1 development',
    time: '1 hr',
    icon: 'icons/duotune/technology/teh008.svg',
    state: 'primary',
  },
  {
    title: 'HR Confidential',
    description: 'Confidential staff documents',
    time: '2 hrs',
    icon: 'icons/duotune/general/gen044.svg',
    state: 'danger',
  },
  {
    title: 'Company HR',
    description: 'Corporeate staff profiles',
    time: '5 hrs',
    icon: 'icons/duotune/finance/fin006.svg',
    state: 'warning',
  },
  {
    title: 'Project Red',
    description: 'New frontend admin theme',
    time: '2 days',
    icon: 'icons/duotune/files/fil023.svg',
    state: 'success',
  },
  {
    title: 'Project Breafing',
    description: 'Product launch status update',
    time: '21 Jan',
    icon: 'icons/duotune/maps/map001.svg',
    state: 'primary',
  },
  {
    title: 'Banner Assets',
    description: 'Collection of banner images',
    time: '21 Jan',
    icon: 'icons/duotune/general/gen006.svg',
    state: 'info',
  },
  {
    title: 'Icon Assets',
    description: 'Collection of SVG icons',
    time: '20 March',
    icon: 'icons/duotune/art/art002.svg',
    state: 'warning',
  },
]



export interface ServicecenterModel {
  comapny_name?: string
  city?: string
  state?: string
  country?: string
  contact_number?: string
  email_address?: string
}

const defaultServicecenters: Array<ServicecenterModel> = [
  {
    comapny_name: 'A-One Electrical',
    city: 'Ahmedabad',
    state: 'Gujarat',
    country: 'India',
    contact_number: '9825465231',
    email_address: 'aoneele@gmail.com',
  },

  {
    comapny_name: 'C M Electronics',
    city: 'Gurukul',
    state: 'Gujarat',
    country: 'India',
    contact_number: '9852412365',
    email_address: 'cmele@gmail.com',
  },

  {
    comapny_name: 'Sunshine Sales & Service',
    city: 'Paldi',
    state: 'Gujarat',
    country: 'India',
    contact_number: '8542314698',
    email_address: 'sunshine@gmail.com',
  },

  {
    comapny_name: 'Ritika Sales And Services',
    city: 'Naroda',
    state: 'Gujarat',
    country: 'India',
    contact_number: '96514236458',
    email_address: 'aritika@gmail.com',
  },
  {
    comapny_name: 'Divya Enterprises',
    city: 'Panchvati',
    state: 'Gujarat',
    country: 'India',
    contact_number: '9952412363',
    email_address: 'divya@gmail.com',
  },

]



export interface DistributorModel {
  comapny_name?: string
  city?: string
  state?: string
  country?: string
  contact_number?: string
  email_address?: string
  pincode?: string
  gst_no?: string
}

const defaultDistributors: Array<DistributorModel> = [
  {
    comapny_name: 'A-One Electrical',
    city: 'Ahmedabad',
    state: 'Gujarat',
    country: 'India',
    contact_number: '9825465231',
    email_address: 'aoneele@gmail.com',
    pincode: '382546',
    gst_no: '235145UJH8IOM56'
  },

  {
    comapny_name: 'A-One Electrical',
    city: 'Ahmedabad',
    state: 'Gujarat',
    country: 'India',
    contact_number: '9825465231',
    email_address: 'aoneele@gmail.com',
    pincode: '382541',
    gst_no: '521OMNH8546BN74'
  },

  {
    comapny_name: 'A-One Electrical',
    city: 'Ahmedabad',
    state: 'Gujarat',
    country: 'India',
    contact_number: '9825465231',
    email_address: 'aoneele@gmail.com',
    pincode: '386521',
    gst_no: '8564IJN518536931'
  },

  {
    comapny_name: 'A-One Electrical',
    city: 'Ahmedabad',
    state: 'Gujarat',
    country: 'India',
    contact_number: '9825465231',
    email_address: 'aoneele@gmail.com',
    pincode: '386512',
    gst_no: '8359IJN518554298'
  },
  {
    comapny_name: 'A-One Electrical',
    city: 'Ahmedabad',
    state: 'Gujarat',
    country: 'India',
    contact_number: '9825465231',
    email_address: 'aoneele@gmail.com',
    pincode: '362528',
    gst_no: '685DGF85412TYH'
  },

]




export interface CustomersupportModel {
  name?: string
  email_address?: string
  contact_number?: string
  status?: string

}


const defaultCustomersupports: Array<CustomersupportModel> = [
  {
    name: 'Mr. Hemang Shah',
    email_address: 'Hemang@gmail.com',
    contact_number: '9825465231',
    status: 'Active'

  },

  {
    name: 'Mr. Hemang Shah',
    email_address: 'Hemang@gmail.com',
    contact_number: '9825465231',
    status: 'Active'
  },

  {
    name: 'Mr. Hemang Shah',
    email_address: 'Hemang@gmail.com',
    contact_number: '9825465231',
    status: 'Inactive'
  },

  {
    name: 'Mr. Hemang Shah',
    email_address: 'Hemang@gmail.com',
    contact_number: '9825465231',
    status: 'Active'
  },
  {
    name: 'Mr. Hemang Shah',
    email_address: 'Hemang@gmail.com',
    contact_number: '9825465231',
    status: 'Inactive'
  },

]



export interface SupportagentModel {
  agent_name?: string
  email_address?: string
  contact_number?: string
  service_center?: string
  status?: string

}


const defaultSupportagents: Array<SupportagentModel> = [
  {
    agent_name: 'Mr. Hemang Shah',
    email_address: 'hemang@gmail.com',
    contact_number: '9825465231',
    service_center: 'Gurukul',
    status: 'Active'
  },

  {
    agent_name: 'Mr. Gaurang Joshi',
    email_address: 'gaurang@gmail.com',
    contact_number: '9458756231',
    service_center: 'Bapunagar',
    status: 'Active'
  },

  {
    agent_name: 'Mr. Kalmesh Thakkar',
    email_address: 'kalmesh@gmail.com',
    contact_number: '9632145789',
    service_center: 'Chanakyapuri',
    status: 'Inactive'
  },

  {
    agent_name: 'Mr. Jayesh Shah',
    email_address: 'jayesh@gmail.com',
    contact_number: '9653214578',
    service_center: 'Sarkhej',
    status: 'Active'
  },
  {
    agent_name: 'Ms. Taniya Bhatt',
    email_address: 'taniya@gmail.com',
    contact_number: '9852463521',
    service_center: 'Nikol',
    status: 'Inactive'

  },

]


export interface CategorymanagementModel {
  name?: string

}


const defaultCategorymanagements: Array<CategorymanagementModel> = [
  {
    name: 'Consumer Electronics',

  },

  {
    name: 'Home Appliances',
  },

  {
    name: 'Consumer Electronics',
  },

  {
    name: 'Home Appliances',
  },
  {
    name: 'Consumer Electronics',
  },

]

export interface ProductmanagementModel {
  name?: string
  category_name?: string

}


const defaultProductmanagements: Array<ProductmanagementModel> = [
  {
    name: 'LED TV',
    category_name: 'Consumer Electronics'

  },

  {
    name: 'Washing Machine Semi Auto',
    category_name: 'Home Appliances'
  },

  {
    name: 'LED TV',
    category_name: 'Consumer Electronics'

  },

  {
    name: 'Washing Machine Semi Auto',
    category_name: 'Home Appliances'
  },

  {
    name: 'LED TV',
    category_name: 'Consumer Electronics'

  },

  {
    name: 'Washing Machine Semi Auto',
    category_name: 'Home Appliances'
  },


]

export interface ModelmanagementModel {
  model_number?: string

}

const defaultModelmanagements: Array<ModelmanagementModel> = [
  {
    model_number: 'AKV3221S'
  },

  {
    model_number: 'AKV-5000SB'
  },

  {
    model_number: 'CRM3221S'
  },

  {
    model_number: 'AKV2414'
  },

  {
    model_number: 'AKV3221SB'

  },
]


export interface PartmanagementModel {
  name?: string
  model_number?: string
  price?: string

}


const defaultPartmanagements: Array<PartmanagementModel> = [
  {
    name: 'Speaker And Main Borad',
    model_number: 'CRM3221S',
    price: '650'

  },

  {
    name: 'Backlight required ',
    model_number: 'AKV3221S',
    price: '400'
  },

  {
    name: 'Main Borad',
    model_number: 'AKV3221SBFL',
    price: '730'
  },

  {
    name: 'SPIN MOTOR AND CAPACITOR',
    model_number: 'AKVWM8200RD',
    price: '1130'
  },
  {
    name: 'MAIN PCB AND SPEAKER ',
    model_number: 'AKV2414S',
    price: '2350'
  },

]


export interface CustomermanagementModel {
  fname?: string
  lname?: string
  city?: string
  state?: string
  country?: string
  contact_number?: string
  phone_number?: string
  email_address?: string
}

const defaultCustomermanagements: Array<CustomermanagementModel> = [
  {
    fname: 'Mr. Rohit',
    lname: 'Shah',
    city: 'Ahmedabad',
    state: 'Gujarat',
    country: 'India',
    contact_number: '9958742354',
    email_address: 'rohit@gmail.com',
    phone_number: '9632145324',
    // email_address:'rohit@gmail.com',
  },

  {
    fname: 'Mr. Amit',
    lname: 'Patel',
    city: 'Ahmedabad',
    state: 'Gujarat',
    country: 'India',
    contact_number: '9523145687',
    email_address: 'amit@gmail.com',
    phone_number: '9825463214',
    // email_address:'amit@gmail.com',
  },

  {
    fname: 'Mr. Milap',
    lname: 'Sachdev',
    city: 'Ahmedabad',
    state: 'Gujarat',
    country: 'India',
    contact_number: '99854625413',
    email_address: 'milap@gmail.com',
    phone_number: '9852412365',
    // email_address:'milap@gmail.com',
  },

  {
    fname: 'Mr. Yogesh',
    lname: 'Makwana',
    city: 'Ahmedabad',
    state: 'Gujarat',
    country: 'India',
    contact_number: '94789521456',
    email_address: 'yogesh@gmail.com',
    phone_number: '9965841235',
    // email_address:'yogesh@gmail.com',
  },
  {
    fname: 'Mr. Mukesh',
    lname: 'Sharma',
    city: 'Ahmedabad',
    state: 'Gujarat',
    country: 'India',
    contact_number: '96985412365',
    email_address: 'mukesh@gmail.com',
    phone_number: '9852145321',
    // email_address:'mukesh@gmail.com',
  },

]


export interface TicketmanagementModel {
  date?: string
  crtd_by?: string
  ticket_no?:string
  model_number?: string
  serial_number?: string
  date_purchase?: String
  dealer_name?: string
  c_name?: string
  ph_number?: string
  pincode?: string
  service_center?: string
  ticket_type?: string
  resol_type?: string
  status?: string
}

const defaultTicketmanagements: Array<TicketmanagementModel> = [
  {
    date: '24/12/2022',
    crtd_by: 'Mr. Jitesh Agrawal',
    ticket_no: '21456',
    model_number: '012ER0WR',
    serial_number: '0004',
    date_purchase: '27/10/22',
    dealer_name: 'Mr. Amit Patel',
    c_name: 'Mr. Hitesh Shah',
    ph_number: '9523145687',
    service_center: 'Bapunagar',
    ticket_type: 'Repair',
    resol_type: 'Information',
    status: 'Pending'

  },

  {
    date: '25/09/2022',
    crtd_by: 'Mr. Maulik Mehta',
    ticket_no: '58745',
    model_number: '051ERT23',
    serial_number: '0028',
    date_purchase: '01/02/21',
    dealer_name: 'Mr. Yogesh Patel',
    c_name: 'Mr. Girish patel',
    ph_number: '985245632',
    service_center: 'Chandlodiya',
    ticket_type: 'Repair',
    resol_type: 'External Issues',
    status: 'On Hold'

  },

  {
    date: '05/03/2023',
    crtd_by: 'Mr. Krunal Vyas',
    ticket_no: '48563',
    model_number: '051ERT23',
    serial_number: '0028',
    date_purchase: '02/03/2023',
    dealer_name: 'Mr. Sunil Pandya',
    c_name: 'Mr. Vivek Padhiyar',
    ph_number: '9958463214',
    service_center: 'Paldi',
    ticket_type: 'Installation',
    resol_type: 'Electrical Fault',
    status: 'Completed'

  },

  {
    date: '08/06/2022',
    crtd_by: 'Mr. Mayank Shah',
    ticket_no: '58456',
    model_number: '041GHN87',
    
    serial_number: '0023',
    date_purchase: '04/04/2023',
    dealer_name: 'Mr. Vishal Rathod',
    c_name: 'Mr. Pragnesh Panchal',
    ph_number: '9825413256',
    service_center: 'Ranip',
    ticket_type: 'Repair',
    resol_type: 'Non-electrical Fault',
    status: 'Pending'
  },

  {
    date: '24/12/2022',
    crtd_by: 'Mr. Jitesh Agrawal',
    ticket_no: '21456',
    model_number: '012ER0WR',
    serial_number: '0004',
    date_purchase: '27/10/22',
    dealer_name: 'Mr. Amit Patel',
    c_name: 'Mr. Hitesh Shah',
    ph_number: '9523145687',
    service_center: 'Bapunagar',
    ticket_type: 'Repair',
    resol_type: 'Information',
    status: 'Pending'

  },

  {
    date: '25/09/2022',
    crtd_by: 'Mr. Maulik Mehta',
    ticket_no: '58745',
    model_number: '051ERT23',
    serial_number: '0028',
    date_purchase: '01/02/21',
    dealer_name: 'Mr. Yogesh Patel',
    c_name: 'Mr. Girish patel',
    ph_number: '985245632',
    service_center: 'Chandlodiya',
    ticket_type: 'Repair',
    resol_type: 'External Issues',
    status: 'On Hold'

  },

  {
    date: '05/03/2023',
    crtd_by: 'Mr. Krunal Vyas',
    ticket_no: '48563',
    model_number: '051ERT23',
    serial_number: '0028',
    date_purchase: '02/03/2023',
    dealer_name: 'Mr. Sunil Pandya',
    c_name: 'Mr. Vivek Padhiyar',
    ph_number: '9958463214',
    service_center: 'Paldi',
    ticket_type: 'Installation',
    resol_type: 'Electrical Fault',
    status: 'Completed'

  },

  {
    date: '08/06/2022',
    crtd_by: 'Mr. Mayank Shah',
    ticket_no: '58456',
    model_number: '041GHN87',
    
    serial_number: '0023',
    date_purchase: '04/04/2023',
    dealer_name: 'Mr. Vishal Rathod',
    c_name: 'Mr. Pragnesh Panchal',
    ph_number: '9825413256',
    service_center: 'Ranip',
    ticket_type: 'Repair',
    resol_type: 'Non-electrical Fault',
    status: 'Pending'
  },


  {
    date: '24/12/2022',
    crtd_by: 'Mr. Jitesh Agrawal',
    ticket_no: '21456',
    model_number: '012ER0WR',
    serial_number: '0004',
    date_purchase: '27/10/22',
    dealer_name: 'Mr. Amit Patel',
    c_name: 'Mr. Hitesh Shah',
    ph_number: '9523145687',
    service_center: 'Bapunagar',
    ticket_type: 'Repair',
    resol_type: 'Information',
    status: 'Pending'

  },

  {
    date: '25/09/2022',
    crtd_by: 'Mr. Maulik Mehta',
    ticket_no: '58745',
    model_number: '051ERT23',
    serial_number: '0028',
    date_purchase: '01/02/21',
    dealer_name: 'Mr. Yogesh Patel',
    c_name: 'Mr. Girish patel',
    ph_number: '985245632',
    service_center: 'Chandlodiya',
    ticket_type: 'Repair',
    resol_type: 'External Issues',
    status: 'On Hold'

  },

  {
    date: '05/03/2023',
    crtd_by: 'Mr. Krunal Vyas',
    ticket_no: '48563',
    model_number: '051ERT23',
    serial_number: '0028',
    date_purchase: '02/03/2023',
    dealer_name: 'Mr. Sunil Pandya',
    c_name: 'Mr. Vivek Padhiyar',
    ph_number: '9958463214',
    service_center: 'Paldi',
    ticket_type: 'Installation',
    resol_type: 'Electrical Fault',
    status: 'Completed'

  },

  {
    date: '08/06/2022',
    crtd_by: 'Mr. Mayank Shah',
    ticket_no: '58456',
    model_number: '041GHN87',
    
    serial_number: '0023',
    date_purchase: '04/04/2023',
    dealer_name: 'Mr. Vishal Rathod',
    c_name: 'Mr. Pragnesh Panchal',
    ph_number: '9825413256',
    service_center: 'Ranip',
    ticket_type: 'Repair',
    resol_type: 'Non-electrical Fault',
    status: 'Pending'
  },


]

export interface SendReceiveSparePartModel {

  ticket_no?: string
  model_number?: string
  serial_number?: string
  date_purchase?: String
  dealer_name?: string
  c_name?: string
  ph_number?: string
  service_center?: string
  part_list?: string
  send_receive?: string

}

const defaultSendReceiveSpareParts: Array<SendReceiveSparePartModel> = [
  {
    ticket_no: '0002',
    model_number: '012ER0WR',
    serial_number: '0004',
    date_purchase: '27/10/22',
    dealer_name: 'Mr. Amit Patel',
    c_name: 'Mr. Hitesh Shah',
    ph_number: '9523145687',
    service_center: 'Chandlodiya',
    part_list: 'Speacker Change set',
    send_receive: 'Sent'


  },

  {
    ticket_no: '0003',
    model_number: '051ERT23',
    serial_number: '0028',
    date_purchase: '01/02/21',
    dealer_name: 'Mr. Yogesh Patel',
    c_name: 'Mr. Girish patel',
    ph_number: '985245632',
    service_center: 'Bapunagar',
    part_list: 'Backlight',
    send_receive: 'Sent'

  },

  {
    ticket_no: '0004',
    model_number: '051ERT23',
    serial_number: '0028',
    date_purchase: '02/03/2023',
    dealer_name: 'Mr. Sunil Pandya',
    c_name: 'Mr. Vivek Padhiyar',
    ph_number: '9958463214',
    service_center: 'Shahibaug',
    part_list: 'PCB Change',
    send_receive: 'Received'


  },

  {
    ticket_no: '0005',
    model_number: '041GHN87',
    serial_number: '0023',
    date_purchase: '04/04/2023',
    dealer_name: 'Mr. Vishal Rathod',
    c_name: 'Mr. Pragnesh Panchal',
    ph_number: '9825413256',
    service_center: 'Nehrunagar',
    part_list: 'Main Board',
    send_receive: 'Received'

  },

]

export interface ClaimmanagementModel {
  ticket_no?: string
  model_number?: string
  serial_number?: string
  date_purchase?: String
  dealer_name?: string
  c_name?: string
  ph_number?: string
  inst_charge?: string
  rep_charge?: string
  status?: string
}

const defaultClaimmanagements: Array<ClaimmanagementModel> = [
  {
    ticket_no: '0001',
    model_number: '012ER0WR',
    serial_number: '0004',
    date_purchase: '27/10/22',
    dealer_name: 'Mr. Amit Patel',
    c_name: 'Mr. Hitesh Shah',
    ph_number: '9523145687',
    inst_charge: '568',
    rep_charge: '-',
    status: 'In Progress'
  },

  {
    ticket_no: '0002',
    model_number: '051ERT23',
    serial_number: '0028',
    date_purchase: '01/02/21',
    dealer_name: 'Mr. Yogesh Patel',
    c_name: 'Mr. Girish patel',
    ph_number: '985245632',
    inst_charge: ' - ',
    rep_charge: '638',
    status: 'Completed'
  },

  {
    ticket_no: '0003',
    model_number: '051ERT23',
    serial_number: '0028',
    date_purchase: '02/03/2023',
    dealer_name: 'Mr. Sunil Pandya',
    c_name: 'Mr. Vivek Padhiyar',
    ph_number: '9958463214',
    inst_charge: '1130',
    rep_charge: '-',
    status: 'In Progress'

  },

  {
    ticket_no: '0004',
    model_number: '041GHN87',
    serial_number: '0023',
    date_purchase: '04/04/2023',
    dealer_name: 'Mr. Vishal Rathod',
    c_name: 'Mr. Pragnesh Panchal',
    ph_number: '9825413256',
    inst_charge: '',
    rep_charge: '240',
    status: 'Assigned' 
  },

  {
    ticket_no: '0005',
    model_number: '041GHN87',
    serial_number: '0023',
    date_purchase: '04/04/2023',
    dealer_name: 'Mr. Vishal Rathod',
    c_name: 'Mr. Pragnesh Panchal',
    ph_number: '9825413256',
    inst_charge: '750',
    rep_charge: '-',
    status: 'Cancelled'
  },


]



export interface ProductreturnmanagementModel {
  date?: string
  distributor_name?: string
  model_number?: string
  serial_number?: string
  prblm_rprt?: string
  actual_prblm?: string
  activity?: string
  charge?: string
  remarks?: string
}

const defaultProductreturnmanagements: Array<ProductreturnmanagementModel> = [
  {
  date: '25/10/2021',
  distributor_name: 'Ritika Sales And Services',
  model_number: 'AKV3221S',
  serial_number: '001YR512432SMT1021104',
  prblm_rprt: 'Back Light Required ',
  actual_prblm: 'Back Light Required ',
  activity: 'Back light Replaced',
  charge: '1600',
  remarks: 'It is a long established fact that a reader will be distracted by the ',
  },

  {
    date: '09/11/2021',
    distributor_name: 'V S Enterprises',
    model_number: 'AKV4242S',
    serial_number: '32sfl0422137',
    prblm_rprt: 'Main Board Required',
    actual_prblm: 'Main Board Required',
    activity: 'Main Board Replaced',
    charge: '3500',
    remarks: 'string',
    },
  

  {
      date: '28/11/2021',
      distributor_name: 'C M Electronics',
      model_number: 'AKV4300SBVFL',
      serial_number: '43SBFCB0622048',
      prblm_rprt: 'Speaker',
      actual_prblm: 'Speaker',
      activity: 'Speacker Change',
      charge: '1200',
      remarks: 'string',
      },
    

      {
        date: '15/12/2021',
        distributor_name: 'J P Technologies',
        model_number: 'AKV3221SBFL',
        serial_number: '32SBFL0522164',
        prblm_rprt: 'PCB Dead',
        actual_prblm: 'PCB Dead',
        activity: 'PCB Replaced',
        charge: '2900',
        remarks: 'string',
        },
      

        {
          date: '17/02/2022',
          distributor_name: 'Jai Maa Bhagwati Enterprises',
          model_number: 'AKVWM8200RD',
          serial_number: '1A8200RD0221001',
          prblm_rprt: 'Back Light Required ',
          actual_prblm: 'Back Light Required ',
          activity: 'Back Light Replaced',
          charge: '1800',
          remarks: 'string',
          },
        


]


export interface SupportticketarchiveModel {
  date?: string
  service_center?: string
  city?: string
  c_name?: string
  p_name?: string
  date_purchase?: String
  crtd_by?: string
  ph_number?: string
  ticket_type?: string
  status?: string  
}

const defaultSupportticketarchives: Array<SupportticketarchiveModel> = [
  {
    date: '24/12/2022',
    service_center: 'V S Enterprises',
    city:'Ahmedabad',
    c_name: 'Mr. Hitesh Shah',
    p_name: 'LED TV',
    date_purchase: '27/10/2022',
    crtd_by: 'Mr. Jitesh Agrawal',
    ph_number: '9825412365',
    ticket_type: 'Repair',
    status:'In Progress',

  },

  {
    date: '25/09/2022',
    service_center: 'C M Electronics',
    city:'JALGAON',
    c_name: 'Mr. RAKESH SINGH',
    p_name: 'LED TV',
    date_purchase: '28/06/2022',
    crtd_by: 'Mr. Yogesh Gajjar',
    ph_number: '9523145687',
    ticket_type: 'Repair',
    status:'On Hold',

  },

  {
    date: '05/03/2023',
    service_center: 'Ritika Sales And Services',
    city:'SURENDRA NAGAR',
    c_name: 'Mr. SANJEEV KUMAR YADAV',
    p_name: 'LED TV',
    date_purchase: '24/12/2022',
    crtd_by: 'Mr. Krunal patel',
    ph_number: '9952132564',
    ticket_type: 'Installation',
    status:'Pending',
  },

  {
    date: '08/06/2022',
    service_center: 'Sunshine Sales & Service',
    city:'BHILWARA',
    c_name: 'Mr. Lalit',
    p_name: 'Washing Machine',
    date_purchase: '27/03/2022',
    crtd_by: 'Mr. Vishal Rathod',
    ph_number: '9632145789',
    ticket_type: 'Installation',
    status:'Completed',

  },

  {
    date: '15/08/2021',
    service_center: 'J P Technologies',
    city:'JALGAON',
    c_name: 'Kamesh Rajendra Pardeshi',
    p_name: 'LED TV',
    date_purchase: '18/03/2021',
    crtd_by: 'Mr. Aiyub',
    ph_number: '9956421365',
    ticket_type: 'Reparing',
    status:'On Hold',

  },

  {
    date: '21/11/2021',
    service_center: 'Amruta Services',
    city:'Rachi',
    c_name: 'Jagannath Gavade',
    p_name: 'Washing Machine',
    date_purchase: '16/03/2021',
    crtd_by: 'Mr. Parth',
    ph_number: '9624513654',
    ticket_type: 'Reparing',
    status:'Cancel',

  },

  {
    date: '28/01/2023',
    service_center: 'Mobitech Solution',
    city:'Kolhapur',
    c_name: 'Badu Namdev',
    p_name: 'Washing Machine',
    date_purchase: '25/01/2023',
    crtd_by: 'Mr. Rajendra Vyas',
    ph_number: '9712456982',
    ticket_type: 'Installation',
    status:'Completed',

  },

  {
    date: '25/12/2021',
    service_center: 'Mobitech Solution',
    city:'Kolhapur',
    c_name: 'Dharmendra Singh',
    p_name: 'LED TV',
    date_purchase: '14/06/2021',
    crtd_by: 'Mr. Pratik Shukla',
    ph_number: '9652314587',
    ticket_type: 'Installation',
    status:'Completed',

  },

]


export interface ClaimarchiveModel {
  date?:string
  ticket_no?: string
  service_center?: string
  city?: string
  c_name?: string
  p_name?: string
  total_charge?: string 
}

const defaultClaimarchives: Array<ClaimarchiveModel> = [
  {
    date: '24/12/2022',
    ticket_no: '0001',
    service_center: 'Sunshine Sales & Service',
    city:'Bhilwada',
    c_name: 'Mr. Hitesh Shah',
    p_name:'LED TV',
    total_charge: '568',
  },

  {
    date: '25/09/2022',
    ticket_no: '0002',
    service_center: 'V S Enterprises',
    city:'Kanpur',
    c_name: 'Mr. Girish patel',
    p_name:'LED TV',
    total_charge: '385',
  },

  {
    date: '05/03/2023',
    ticket_no: '0003',
    service_center: 'C M Electronics',
    city:'Surendranagar',
    c_name: 'Mr. Vivek Padhiyar',
    p_name:'Washing Machine',
    total_charge: '1130',

  },

  {
    date: '08/06/2022',
    ticket_no: '0004',
    service_center: 'Ritika Sales And Services',
    city:'Solapur',
    c_name: 'Mr. Pragnesh Panchal',
    p_name:'Washing Machine',
    total_charge: '550',
  },

  {
    date: '05/02/2021',
    ticket_no: '0005',
    service_center: 'Amruta Services',
    city:'Kolhapur',
    c_name: 'Mr. Pragnesh Panchal',
    p_name:'LED TV',
    total_charge: '750',
  },

  {
    date: '25/04/2022',
    ticket_no: '0006',
    service_center: 'J P Technologies',
    city:'Jalgaon',
    c_name: 'Mr. Yogesh Trivadi',
    p_name:'Washing Machine',
    total_charge: '350',
  },

  {
    date: '28/06/2021',
    ticket_no: '0007',
    service_center: 'Divya Enterprises',
    city:'Bhopal',
    c_name: 'Mr. Manish Shah',
    p_name:'Washing Machine',
    total_charge: '830',
  },

  {
    date: '30/09/2021',
    ticket_no: '0008',
    service_center: 'Mobitech Solution',
    city:'Bhopal',
    c_name: 'Mr. Girish Gohel',
    p_name:'LED TV',
    total_charge: '550',
  },

  {
    date: '16/05/2020',
    ticket_no: '0009',
    service_center: 'Swami Services',
    city:'Ahmedabad',
    c_name: 'Mr. Mayank Chauhan',
    p_name:'LED TV',
    total_charge: '400',
  },


  {
    date: '11/10/2020',
    ticket_no: '0010',
    service_center: 'Swami Services',
    city:'Navsari',
    c_name: 'Mr. Raj Kansara',
    p_name:'LED TV',
    total_charge: '682',
  },



]


export interface SentreceivepartModel {
  ticket_no?: string
  service_center?: string
  city?: string
  p_name?: string
  model_number?: string
  part_list?: string
  quantity?: string
  amount?: string
}

const defaultSentreceiveparts: Array<SentreceivepartModel> = [
  {
    ticket_no: '0001',
    service_center: 'Sunshine Sales & Service',
    city:'BHILWARA',
    p_name:'LED TV',
    model_number: 'AKV3221S',
    part_list: 'Backlight',
    quantity: '3',
    amount: '2580',

  },

  {
    ticket_no: '0002',
    service_center: 'V S Enterprises',
    city:'Kanpur',
    p_name:'LED TV',
    model_number: 'AKV3221S',
    part_list: 'Main Board',
    quantity: '3',
    amount: '1800',

  },

  {
    ticket_no: '0003',
    service_center: 'C M Electronics',
    city:'Surendranagar',
    p_name:'Washing Machine',
    model_number: 'AKVWM8200RD ',
    part_list: 'Spin Motor',
    quantity: '32',
    amount: '1400',

  },

  {
    ticket_no: '0004',
    service_center: 'Ritika Sales And Services',
    city:'Solapur',
    p_name:'LED TV',
    model_number: '012ER0WR',
    part_list: 'Speacker',
    quantity: '8',
    amount: '3200',

  },

  {
    ticket_no: '0005',
    service_center: 'Amruta Services',
    city:'Kolhapur',
    p_name:'LED TV',
    model_number: '012ER0WR',
    part_list: 'PCB Dead',
    quantity: '4',
    amount: '2200',

  },

  {
    ticket_no: '0006',
    service_center: 'J P Technologies',
    city:'Jalgaon',
    p_name:'Washing Machine',
    model_number: 'AKV3221S',
    part_list: 'Panel Defective',
    quantity: '2',
    amount: '620',

  },

  {
    ticket_no: '0007',
    service_center: 'Ekhankar Mobile Shop',
    city:'Bhilwara',
    p_name:'Washing Machine',
    model_number: 'AKV2414S',
    part_list: 'Speaker Defective',
    quantity: '5',
    amount: '1120',

  },

  {
    ticket_no: '0008',
    service_center: 'Divya Enterprises',
    city:'Chhindwara',
    p_name:'LED TV',
    model_number: 'AKV4242S',
    part_list: 'Backlight',
    quantity: '11',
    amount: '3800',

  },


  {
    ticket_no: '0009',
    service_center: 'Digital Service',
    city:'Surendranagar',
    p_name:'LED TV',
    model_number: 'AKV3221S',
    part_list: 'Main Board ',
    quantity: '4',
    amount: '2200',

  },

  {
    ticket_no: '0010',
    service_center: 'J P Technologies',
    city:'Kolhapur',
    p_name:'LED TV',
    model_number: 'AKV3221SFL',
    part_list: 'Speacker Change',
    quantity: '2',
    amount: '930',

  },

]


export interface SupportticketsanalysisreportModel {
  date?: string
  ticket_no?: string
  crtd_by?: string
  service_center?: string
  p_name?: string
  resl_type?: string
}

const defaultSupportticketsanalysisreports: Array<SupportticketsanalysisreportModel> = [
  {
    date: '24/12/2022',
    ticket_no: '001',
    crtd_by: 'Mr. Jitesh Agrawal',
    service_center: 'Bapunagar',
    p_name: 'LED TV',
    resl_type: 'External Issues'

  },

  {
    date: '25/09/2022',
    ticket_no: '002',
    crtd_by: 'Mr. Maulik Mehta',
    service_center: 'Chandkheda',
    p_name: 'LED TV',
    resl_type: 'Electrical Fault'

  },

  {
    date: '05/03/2022',
    ticket_no: '003',
    crtd_by: 'Mr. Krunal Vyas',
    service_center: 'Ghatlodiya',
    p_name: 'Washing Machine',
    resl_type: 'Electrical Fault'
  },

  {
    date: '08/02/2022',
    ticket_no: '004',
    crtd_by: 'Mr. Mayank Shah',
    service_center: 'Chanakyapuri',
    p_name: 'Washing Machine',
    resl_type: 'Non-Electrical Fault'
  },

  {
    date: '15/12/2021',
    ticket_no: '005',
    crtd_by: 'Mr. Mehul Vyas',
    service_center: 'Prahladnagar',
    p_name: 'LED TV',
    resl_type: 'Non-Electrical Fault'
  },

  {
    date: '08/11/2021',
    ticket_no: '006',
    crtd_by: 'Mr. Girish Panchal',
    service_center: 'Panchvati',
    p_name: 'LED TV',
    resl_type: 'Electrical Fault'
  },

  {
    date: '12/09/2021',
    ticket_no: '007',
    crtd_by: 'Mr. Pravin Dubal',
    service_center: 'Sarkhej',
    p_name: 'Washing Machine',
    resl_type: 'Information'
  },

  {
    date: '18/08/2021',
    ticket_no: '008',
    crtd_by: 'Mr. Amar Singh',
    service_center: 'Naroda',
    p_name: 'LED TV',
    resl_type: 'External Issues'
  },

  {
    date: '26/06/2021',
    ticket_no: '009',
    crtd_by: 'Mr. Krunal Kansara',
    service_center: 'Isanpur',
    p_name: 'LED TV',
    resl_type: 'Electrical Fault'
  },


  {
    date: '20/05/2021',
    ticket_no: '0010',
    crtd_by: 'Mr. Vijay Prajapati',
    service_center: 'Ghodasar',
    p_name: 'Washing Machine',
    resl_type: 'Electrical Fault'
  },

]


export interface PendingtoreceivepartModel {
  ticket_no?: string
  service_center?: string
  city?: string
  p_name?: string
  model_number?: string
  part_list?: string
  quantity?: string
  amount?: string
}

const defaultPendingtoreceiveparts: Array<PendingtoreceivepartModel> = [
  {
    ticket_no: '0001',
    service_center: 'Sunshine Sales & Service',
    city:'BHILWARA',
    p_name:'LED TV',
    model_number: 'AKV3221S',
    part_list: 'Backlight',
    quantity: '3',
    amount: '2580',

  },

  {
    ticket_no: '0002',
    service_center: 'V S Enterprises',
    city:'Kanpur',
    p_name:'LED TV',
    model_number: 'AKV3221S',
    part_list: 'Main Board',
    quantity: '3',
    amount: '1800',

  },

  {
    ticket_no: '0003',
    service_center: 'C M Electronics',
    city:'Surendranagar',
    p_name:'Washing Machine',
    model_number: 'AKVWM8200RD ',
    part_list: 'Spin Motor',
    quantity: '32',
    amount: '1400',

  },

  {
    ticket_no: '0004',
    service_center: 'Ritika Sales And Services',
    city:'Solapur',
    p_name:'LED TV',
    model_number: '012ER0WR',
    part_list: 'Speacker',
    quantity: '8',
    amount: '3200',

  },

  {
    ticket_no: '0005',
    service_center: 'Amruta Services',
    city:'Kolhapur',
    p_name:'LED TV',
    model_number: '012ER0WR',
    part_list: 'PCB Dead',
    quantity: '4',
    amount: '2200',

  },


  {
    ticket_no: '0006',
    service_center: 'J P Technologies',
    city:'Jalgaon',
    p_name:'Washing Machine',
    model_number: 'AKV3221S',
    part_list: 'Panel Defective',
    quantity: '2',
    amount: '620',

  },

  {
    ticket_no: '0007',
    service_center: 'Ekhankar Mobile Shop',
    city:'Bhilwara',
    p_name:'Washing Machine',
    model_number: 'AKV2414S',
    part_list: 'Speaker Defective',
    quantity: '5',
    amount: '1120',

  },

  {
    ticket_no: '0008',
    service_center: 'Divya Enterprises',
    city:'Chhindwara',
    p_name:'LED TV',
    model_number: 'AKV4242S',
    part_list: 'Backlight',
    quantity: '11',
    amount: '3800',

  },

  {
    ticket_no: '0009',
    service_center: 'Digital Service',
    city:'Surendranagar',
    p_name:'LED TV',
    model_number: 'AKV3221S',
    part_list: 'Main Board ',
    quantity: '4',
    amount: '2200',

  },

  {
    ticket_no: '0010',
    service_center: 'J P Technologies',
    city:'Kolhapur',
    p_name:'LED TV',
    model_number: 'AKV3221SFL',
    part_list: 'Speacker Change',
    quantity: '2',
    amount: '930',

  },

]




export interface LogModel {
  code: string
  state: 'success' | 'danger' | 'warning'
  message: string
  time: string
}

const defaultLogs: Array<LogModel> = [
  { code: '200 OK', state: 'success', message: 'New order', time: 'Just now' },
  { code: '500 ERR', state: 'danger', message: 'New customer', time: '2 hrs' },
  { code: '200 OK', state: 'success', message: 'Payment process', time: '5 hrs' },
  { code: '300 WRN', state: 'warning', message: 'Search query', time: '2 days' },
  { code: '200 OK', state: 'success', message: 'API connection', time: '1 week' },
  { code: '200 OK', state: 'success', message: 'Database restore', time: 'Mar 5' },
  { code: '300 WRN', state: 'warning', message: 'System update', time: 'May 15' },
  { code: '300 WRN', state: 'warning', message: 'Server OS update', time: 'Apr 3' },
  { code: '300 WRN', state: 'warning', message: 'API rollback', time: 'Jun 30' },
  { code: '500 ERR', state: 'danger', message: 'Refund process', time: 'Jul 10' },
  { code: '500 ERR', state: 'danger', message: 'Withdrawal process', time: 'Sep 10' },
  { code: '500 ERR', state: 'danger', message: 'Mail tasks', time: 'Dec 10' },
]




export { defaultMessages, defaultUserInfos, defaultAlerts, defaultLogs, messageFromClient, defaultServicecenters,
   defaultDistributors, defaultCustomersupports, defaultSupportagents, defaultCategorymanagements, defaultProductmanagements,
    defaultPartmanagements, defaultCustomermanagements, defaultTicketmanagements, defaultSendReceiveSpareParts,
     defaultClaimmanagements, defaultSupportticketarchives, defaultClaimarchives, defaultSentreceiveparts, 
     defaultSupportticketsanalysisreports, defaultPendingtoreceiveparts, defaultModelmanagements, defaultProductreturnmanagements }
