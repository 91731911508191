import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {MostDelayedSupportTicket} from './widgets/MostDelayedSupportTickets'
import {RecentlyRegisteredSupportTickets} from './widgets/RecentlyRegisteredSupportTickets'
import {ServiceCenterWiseOpenTickets} from './widgets/ServiceCenterWiseOpenTickets'
// import {ServiceCenterWiseOpenClaimsInNumber} from './widgets/ServiceCenterWiseOpenClaimsInNumber'
// import {ServiceCenterWiseOpenClaimsInAmount} from './widgets/ServiceCenterWiseOpenClaimsInAmount'
import {TopPerformingServiceCenters} from './widgets/TopPerformingServiceCenters'
import {DashboardStatistics} from './widgets/DashboardStatistics'
import {adminPremission} from '../../../utils/role'
import { TodayTargetedTickets } from './widgets/TodayTargetedTickets'

const DashboardPage: FC = () => (
  <>
    <div className='col-xl-12'>
      <DashboardStatistics />
    </div>
    {adminPremission() ? (
      <div className='row mt-5 fxd-higt-tbl'>
        <div className='col-xl-6'>
          <ServiceCenterWiseOpenTickets className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>
        {/* <div className='col-xl-6'>
          <ServiceCenterWiseOpenClaimsInNumber className='card-xxl-stretch mb-5 mb-xl-10' />
        </div> */}
        {/* <div className='col-xl-6'>
          <ServiceCenterWiseOpenClaimsInAmount className='card-xxl-stretch mb-5 mb-xl-10' />
        </div> */}
        <div className='col-xl-6'>
          <TopPerformingServiceCenters className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>
      </div>
    ) : (
      <></>
    )}

    <div className='col-xl-12 fxd-higt-tbl'>
      <MostDelayedSupportTicket className='card-xxl-stretch mb-5 mb-xl-10' />
    </div>
    {adminPremission() ? (
      <></>
    ) : (
      <div className='col-xl-12 fxd-higt-tbl'>
        <TodayTargetedTickets className='card-xxl-stretch mb-5 mb-xl-10' />
      </div>
    )}
    <div className='col-xl-12 fxd-higt-tbl'>
      <RecentlyRegisteredSupportTickets className='card-xxl-stretch mb-5 mb-xl-10' />
    </div>
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <div>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </div>
  )
}

export {DashboardWrapper}
