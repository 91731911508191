import { alertMessageAction } from "./types";

export const setMessage = (status,message,time=2) => {
    return {
        type: alertMessageAction.SET_MESSAGE,
        message: message,
        status: status,
        time: time
    };
}

export const removeMessage = (status,message,time=2) => {
    return {
        type: alertMessageAction.REMOVE_MESSAGE,
        message: message,
        status: status,
        time: time
    };
}