import { alertMessageAction } from "./types"

const initialState = {
    message: "",
    status: "",
    time: 2
}

export const alertMessageReducer = (state = initialState,{type, message, status, time}) => {
    switch(type){
        case alertMessageAction.SET_MESSAGE:
            return {
                ...state, 
                message: message,
                status: status,
                time: time,
            }
        case alertMessageAction.REMOVE_MESSAGE:
            return {
                ...state, 
                message: "",
                status: "",
                time: 2,
            }      
        default: return state;
    }
}

