import { commonListAction } from "./types"

const initialState = {
    countryList: [],
    stateList: [],
    cityList: [],
    pincodeList: [],
    categoryList: [],
    productList: [],
}

export const commonListReducer = (state = initialState,{type, payload}) => {
    switch(type){
        case commonListAction.SET_COUNTRY_LIST:
            return {...state, countryList: payload}
        case commonListAction.SET_STATE_LIST:
            return {...state, stateList: payload}
        case commonListAction.SET_CITY_LIST:
            return {...state, cityList: payload}
        case commonListAction.SET_PINCODE_LIST:
            return {...state, pincodeList: payload}  
        case commonListAction.SET_CATEGORY_LIST:
            return {...state, categoryList: payload}
        case commonListAction.SET_PRODUCT_LIST:
            return {...state, productList: payload}
        default: return state;
    }
}

