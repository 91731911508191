/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {login} from '../core/_requests'
import {useDispatch} from 'react-redux'
import {setMessage} from '../../../../store/alertMessage/actions'
import {setAuth} from '../../../../store/authDetail/actions'
import {roleArray} from '../../../../utils/roleId'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 character is required')
    .max(50, 'Maximum 50 character is required')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .max(50, 'Maximum 50 character is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [visialbe, setVisiable] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        await login(values.email, values.password)
          .then((data: any) => {
            if (data.responseStatus == 200) {
              if (data?.responseData?.token) {
                let roleID = data?.responseData?.user?.role_id
                if (roleArray.includes(roleID)) {
                  dispatch(setAuth(data?.responseData?.token, roleID, data?.responseData?.user))
                  dispatch(setMessage('success', data.responseMessage))
                  // setLoggedDetails({userInfo: data?.responseData?.user})
                } else {
                  dispatch(setMessage('error', "Role Id don't match"))
                }
              } else {
                dispatch(setMessage('error', 'Something Went Wrong'))
              }
              setLoading(false)
            } else {
              dispatch(setMessage('error', data.responseMessage))
              setLoading(false)
            }
          })
          .catch((error) => {
            if (error?.response?.data?.responseData?.msg) {
              dispatch(setMessage('error', error?.response?.data?.responseData?.msg))
            } else if (error?.response?.data?.responseMessage) {
              dispatch(setMessage('error', error?.response?.data?.responseMessage))
            } else {
              dispatch(setMessage('error', error?.message))
            }
            setLoading(false)
          })
      } catch (error: any) {
        dispatch(setMessage('error', error.message ? error.message : 'Something Went Wrong'))
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className=' fw-bolder mb-3 '>Login</h1>
        {/* <div className='text-gray-500 fw-semibold fs-4'>Welcome to JV Technology</div> */}
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='required fw-bold fs-6 mb-2'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='required fw-bold fs-6 mb-2'>Password</label>
        <div className='input-group fv-col mb-3'>
          <input
            placeholder='Password'
            type={`${visialbe === true ? 'text' : 'password'}`}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <div className='input-group-append'>
            {visialbe === true ? (
              <span className='input-group-text' onClick={() => setVisiable(false)}>
                <VisibilityIcon />
              </span>
            ) : (
              <span className='input-group-text' onClick={() => setVisiable(true)}>
                <VisibilityOffIcon />
              </span>
            )}
          </div>
          {/* <span onClick={() => setVisiable(true)}>
            <VisibilityOffIcon />
          </span>

          <span onClick={() => setVisiable(false)}>
            <VisibilityIcon />
          </span> */}
          {/* <i className={`fa-sharp fa-solid fa-eye fs-5 text-primary`}></i> */}
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn '
          disabled={formik.isSubmitting || !formik.isValid || loading}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}
    </form>
  )
}
