import {authAction} from './types'

export const setAuth = (token, role, user) => {
  return {
    type: authAction.SET_AUTH,
    payload: {
      token: token,
      role: role,
      isLoggedIn: true,
      userDetail: user,
    },
  }
}

export const removeAuth = () => {
  return {
    type: authAction.REMOVE_AUTH,
    token: null,
    role: null,
    isLoggedIn: false,
    userDetail: null,
  }
}
