/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Spinner } from 'react-bootstrap'
import { apiUrl } from '../../../../apiurl'

const API_URL = apiUrl
const ALL_Top_PerForming_URL = `${API_URL}/service/top-performing`
type Props = {
  className: string
}
const TopPerformingServiceCenters: React.FC<Props> = ({ className }) => {
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = async () => {
    try {
      const responceData = await axios.get(ALL_Top_PerForming_URL)

      setData(responceData?.data?.responseData ?? [])
      setIsLoading(false);
    } catch (error) {
      console.error('Error -->:', error);
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }


  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Top Performing Service Centers</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        {isLoading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle table-row-dashed fs-6 gy-5 table-striped dataTable no-footer'>
              {/* begin::Table head */}
              <thead>
                <tr className='fs-6 fw-bold text-gray-400 border-bottom-0 text-uppercase'>
                  <th>Service Center</th>
                  <th>Installation Service</th>
                  <th>Repair Service</th>
                  <th>Software Service</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody className='text-gray-600'>
                {data.map((item: any, index: number) => (
                  <tr key={index}>
                    <th>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {item.ServiceCenter}
                      </a>
                    </th>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {item.Installation}
                      </a>
                    </td>
                    <td>
                      <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>{item.Repairs}</div>
                    </td>
                    <td>
                      <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>{item.Software}</div>
                    </td>
                  </tr>))}

              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        )}
        {/* end::Table container */}
      </div>
    </div>
  )
}

export { TopPerformingServiceCenters }
