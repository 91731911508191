import {authAction} from './types'
const isLoggedIn = localStorage.getItem('isLoggedIn')
const token = localStorage.getItem('token')
const userDetail = localStorage.getItem('userDetail')
  ? JSON.parse(localStorage.getItem('userDetail'))
  : {}

const initialState = {
  isLoggedIn: isLoggedIn == 'true' ? isLoggedIn : false,
  token: token ? token : null,
  role: null,
  userDetail: userDetail,
}

export const authDetailReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case authAction.SET_AUTH:
      localStorage.setItem('token', payload.token)
      localStorage.setItem('isLoggedIn', payload.isLoggedIn)
      localStorage.setItem('role', payload.role)
      localStorage.setItem('userDetail', JSON.stringify(payload?.userDetail))
      return {
        ...state,
        isLoggedIn: payload.isLoggedIn,
        token: payload.token,
        role: payload.role,
        userDetail: payload.userDetail,
      }
    case authAction.REMOVE_AUTH:
      localStorage.clear()
      return {...state, isLoggedIn: false, token: null}
    default:
      return state
  }
}
