/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {adminPremission} from '../../../../utils/role'
import axios from 'axios'
import {Spinner} from 'react-bootstrap'
import {apiUrl} from '../../../../apiurl'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {checkDisplayValue, formatDate} from '../../../../utils'

const recentlyRegisteredSupportTickects = `${apiUrl}/support-tickets/list-support-tickets?perPage=5&page=1&todays_target=true`

type Props = {
  className: string
}

const TodayTargetedTickets: React.FC<Props> = ({className}) => {
  const [data, setData] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()
  useEffect(() => {
    fetchData()
  }, [])

  const userDetail = useSelector((user: any) => user?.authDetail?.userDetail)
  const serviceCenterId = adminPremission() ? '' : userDetail?.servicecenter_id

  const fetchData = async () => {
    try {
      const responceData = await axios.get(
        `${recentlyRegisteredSupportTickects}&service_center_id=${serviceCenterId}`
      )

      setData(responceData?.data?.responseData ?? [])
      setIsLoading(false)
    } catch (error) {
      // console.error('Error -->:', error);
      setIsLoading(false)
    }
  }

  // if (isLoading) {
  //   return <div>Loading...</div>
  // }
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          {/* {adminPremission() ? ( */}
          {/* <span className='card-label fw-bold fs-3 mb-1'>
              Recently Registered Support Tickets
            </span> */}
          {/* ) : (? */}
          <span className='card-label fw-bold fs-3 mb-1'>Today’s Targeted Support Tickets </span>
          {/* )} */}
          {/* <span className='text-muted fw-semibold fs-7'>Today’s Targeted Tickets</span> */}
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-light'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={(e: any) => {
              e.preventDefault()
              navigate('/ticket-management/ticketmanagements')
            }}
          >
            View All
          </button>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        {isLoading ? (
          <div className='text-center'>
            <Spinner animation='border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle table-row-dashed fs-6 gy-5 table-striped dataTable no-footer'>
              {/* begin::Table head */}
              <thead>
                <tr className='fs-6 fw-bold text-gray-400 border-bottom-0 text-uppercase'>
                  <th>Date</th>
                  <th>Created By</th>
                  <th>Model No.</th>
                  <th>Serial No.</th>
                  <th>D.O.P</th>
                  <th>Dealer</th>
                  <th>Customer</th>
                  <th>Phone</th>
                  <th>Service Center</th>
                  <th>Type of Ticket</th>
                  <th>Status</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody className='text-gray-600'>
                {data.length > 0 ? (
                  data.map((item: any, index: number) => (
                    <tr key={index}>
                      <th>
                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {checkDisplayValue(moment(item.date).format('YYYY-MM-DD'))}
                        </a>
                      </th>
                      <td>
                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {checkDisplayValue(item.userInfo?.name)}
                        </a>
                      </td>
                      <td>
                        <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {checkDisplayValue(item.modelInfo?.name)}
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {checkDisplayValue(item.serial_number)}
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {checkDisplayValue(moment(item.date_of_purchase).format('YYYY-MM-DD'))}
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {checkDisplayValue(item.distributorInfo?.name)}
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {checkDisplayValue(item.customerInfo?.name)}
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {checkDisplayValue(item.customerInfo?.phonenumber)}
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {checkDisplayValue(item.serviceCenterInfo?.company_name)}
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {checkDisplayValue(item.supportTicketTypeInfo?.name)}
                        </div>
                      </td>
                      <td className='text-start'>
                        <span
                          className={`badge py-3 px-4 fs-7 
                  ${
                    item.supportTicketStatusInfo.name === 'Rejected'
                      ? 'badge-light-danger'
                      : item.supportTicketStatusInfo.name === 'Cancelled'
                      ? 'badge-light-danger'
                      : item.supportTicketStatusInfo.name === 'In Progress'
                      ? 'badge-light-primary'
                      : item.supportTicketStatusInfo.name === 'New'
                      ? 'badge-light-primary'
                      : item.supportTicketStatusInfo.name === 'Assigned'
                      ? 'badge-light-primary'
                      : item.supportTicketStatusInfo.name === 'Completed'
                      ? 'badge-light-success'
                      : 'badge-light-warning'
                  }`}
                        >
                          {checkDisplayValue(item.supportTicketStatusInfo?.name)}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={11}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        )}
        {/* end::Table container */}
      </div>
    </div>
  )
  // return (
  //   <div className={`card ${className}`}>
  //     {/* begin::Header */}
  //     <div className='card-header border-0 pt-5'>
  //       <h3 className='card-title align-items-start flex-column'>
  //         <span className='card-label fw-bold fs-3 mb-1'>Today’s Targeted Tickets</span>
  //       </h3>
  //       <div className='card-toolbar'>
  //         {/* begin::Menu */}
  //         <button
  //           type='button'
  //           className='btn btn-sm btn-light'
  //           data-kt-menu-trigger='click'
  //           data-kt-menu-placement='bottom-end'
  //           data-kt-menu-flip='top-end'
  //         >
  //           View All
  //         </button>
  //       </div>
  //     </div>
  //     {/* end::Header */}
  //     {/* begin::Body */}
  //     <div className='card-body py-3'>
  //       {/* begin::Table container */}
  //       <div className='table-responsive'>
  //         {/* begin::Table */}
  //         <table className='table align-middle table-row-dashed fs-6 gy-5 table-striped dataTable no-footer'>
  //           {/* begin::Table head */}
  //           <thead>
  //             <tr className='fs-6 fw-bold text-gray-400 border-bottom-0 text-uppercase'>
  //               <th>Date</th>
  //               <th>Created By</th>
  //               <th>Model No.</th>
  //               <th>Serial No.</th>
  //               <th>D.O.P</th>
  //               <th>Dealer</th>
  //               <th>Customer</th>
  //               <th>Phone</th>
  //               <th>Service Center</th>
  //               <th>Type of Ticket</th>
  //               <th className='text-end'>Status</th>
  //             </tr>
  //           </thead>
  //           {/* end::Table head */}
  //           {/* begin::Table body */}
  //           <tbody className='text-gray-600'>
  //             <tr>
  //               <th>
  //                 <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
  //                   24/12/2022
  //                 </a>
  //               </th>
  //               <td>
  //                 <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
  //                   Mr. Jitesh Agrawal
  //                 </a>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>27/10/22</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
  //                   Mr. Amit Patel
  //                 </div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
  //                   Mr. Hitesh Shah
  //                 </div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>9523145687</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>Bapunagar</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>Repair</div>
  //               </td>
  //               <td className='text-end'>
  //                 <span className='badge py-3 px-4 fs-7 badge-light-primary'>Pending</span>
  //               </td>
  //             </tr>
  //             <tr>
  //               <th>
  //                 <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
  //                   24/12/2022
  //                 </a>
  //               </th>
  //               <td>
  //                 <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
  //                   Mr. Jitesh Agrawal
  //                 </a>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>27/10/22</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
  //                   Mr. Amit Patel
  //                 </div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
  //                   Mr. Hitesh Shah
  //                 </div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>9523145687</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>Bapunagar</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>Repair</div>
  //               </td>
  //               <td className='text-end'>
  //                 <span className='badge py-3 px-4 fs-7 badge-light-success'>Completed</span>
  //               </td>
  //             </tr>
  //             <tr>
  //               <th>
  //                 <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
  //                   24/12/2022
  //                 </a>
  //               </th>
  //               <td>
  //                 <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
  //                   Mr. Jitesh Agrawal
  //                 </a>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>27/10/22</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
  //                   Mr. Amit Patel
  //                 </div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
  //                   Mr. Hitesh Shah
  //                 </div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>9523145687</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>Bapunagar</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>Repair</div>
  //               </td>
  //               <td className='text-end'>
  //                 <span className='badge py-3 px-4 fs-7 badge-light-danger'>Rejected</span>
  //               </td>
  //             </tr>
  //             <tr>
  //               <th>
  //                 <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
  //                   24/12/2022
  //                 </a>
  //               </th>
  //               <td>
  //                 <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
  //                   Mr. Jitesh Agrawal
  //                 </a>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>27/10/22</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
  //                   Mr. Amit Patel
  //                 </div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
  //                   Mr. Hitesh Shah
  //                 </div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>9523145687</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>Bapunagar</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>Repair</div>
  //               </td>
  //               <td className='text-end'>
  //                 <span className='badge py-3 px-4 fs-7 badge-light-warning'>On-Hold</span>
  //               </td>
  //             </tr>
  //             <tr>
  //               <th>
  //                 <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
  //                   24/12/2022
  //                 </a>
  //               </th>
  //               <td>
  //                 <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
  //                   Mr. Jitesh Agrawal
  //                 </a>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>27/10/22</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
  //                   Mr. Amit Patel
  //                 </div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
  //                   Mr. Hitesh Shah
  //                 </div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>9523145687</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>Bapunagar</div>
  //               </td>
  //               <td>
  //                 <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>Repair</div>
  //               </td>
  //               <td className='text-end'>
  //                 <span className='badge py-3 px-4 fs-7 badge-light-primary'>Pending</span>
  //               </td>
  //             </tr>
  //           </tbody>
  //           {/* end::Table body */}
  //         </table>
  //         {/* end::Table */}
  //       </div>
  //       {/* end::Table container */}
  //     </div>
  //   </div>
  // )
}

export {TodayTargetedTickets}
