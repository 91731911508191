import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import { apiUrl } from '../../../../apiurl'

const API_URL = apiUrl

export const LOGIN_URL = `${API_URL}/user/login`
export const FORGOT_PASSWORD_URL = `${API_URL}/user/forgotPassword`
export const RESET_PASSWORD_URL = `${API_URL}/user/resetPassword`
export const LOGOUT_URL = `${API_URL}/user/logout`
export const REGISTER_URL = `${API_URL}/register`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<any>(LOGIN_URL, {email, password}).then((response)=>response.data)
}

export function forgotPassword(data: any) {
  return axios.post(FORGOT_PASSWORD_URL,data).then((response)=>response.data)
}

export function resetPassword(data: any) {
  return axios.post(RESET_PASSWORD_URL,data).then((response)=>response.data)
}

export function logoutApi() {
  return axios.post(LOGOUT_URL).then((response)=>response.data)
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
